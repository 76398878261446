/**
 * key-value (value-label) pairs for the molecules
 *
 * value - the value the server receives
 * label - the value displayed to the user (allows for subscripts)
 */
export const molecules = {
  C2H2: "C₂H₂",
  C2H4: "C₂H₄",
  C2H6: "C₂H₆",
  C2N2: "C₂N₂",
  C4H2: "C₄H₂",
  CF4: "CF₄",
  CH3Br: "CH₃Br",
  CH3Cl: "CH₃Cl",
  CH3CN: "CH₃CN",
  CH3OH: "CH₃OH",
  CH4: "CH₄",
  ClO: "ClO",
  ClONO2: "ClONO₂",
  CO: "CO",
  CO2: "CO₂",
  COCl2: "COCl₂",
  COF2: "COF₂",
  CS: "CS",
  H2: "H₂",
  H2CO: "H₂CO",
  H2O: "H₂O",
  H2O2: "H₂O₂",
  H2S: "H₂S",
  HBr: "HBr",
  HC3N: "HC₃N",
  HCl: "HCl",
  HCN: "HCN",
  HCOOH: "HCOOH",
  HF: "HF",
  HI: "HI",
  HNO3: "HNO₃",
  HO2: "HO₂",
  HOBr: "HOBr",
  HOCl: "HOCl",
  N2: "N₂",
  N2O: "N₂O",
  NH3: "NH₃",
  NO: "NO",
  "NO+": "NO+",
  NO2: "NO₂",
  O: "O",
  O2: "O₂",
  O3: "O₃",
  OCS: "OCS",
  OH: "OH",
  PH3: "PH₃",
  SF6: "SF₆",
  SO2: "SO₂",
  SO3: "SO₃",
};
